import BlueStar from "shared/catalog/AvailablePhoneCard/blue-star.svg";
import { useI18n } from "utils/with-i18n.utils";

export const OutOfStockOverlay = () => {
  const i18n = useI18n();

  return (
    <div className="flex overflow-hidden absolute top-0 left-0 z-10 justify-center items-center w-full h-full bg-white/50 rounded-lg">
      <div className="flex gap-x-1 justify-center items-center py-1 w-full text-xs text-center bg-yellow border-y border-y-black lg:gap-x-2 lg:text-lg">
        <BlueStar className="lg:hidden" viewBox="-10 -10 50 50" />
        <BlueStar className="hidden lg:block" />
        {i18n.t("catalog.badge.outOfStock")}
        <BlueStar className="lg:hidden" viewBox="-10 -10 50 50" />
        <BlueStar className="hidden lg:block" />
      </div>
    </div>
  );
};
