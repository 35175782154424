import cx from "classnames";
import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from "react";

type CatalogLayoutExtraProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

type CatalogLayoutProps = PropsWithChildren<CatalogLayoutExtraProps>;

export const CatalogLayout = ({
  children,
  className,
  ...props
}: CatalogLayoutProps) => (
  <div
    className={cx(
      "flex flex-wrap gap-x-4 gap-y-8 justify-center px-2 w-full",
      className,
    )}
    {...props}
  >
    {children}
  </div>
);
