import { Maybe } from "graphql";

import { useSettings } from "shared/SettingsProvider/useSettings";
import { OutOfStockOverlay } from "shared/catalog/AvailablePhoneCard/OutOfStockOverlay";
import { UpfrontLabel } from "shared/catalog/AvailablePhoneCard/UpfrontLabel";
import DropArrow from "shared/catalog/AvailablePhoneCard/drop-arrow.svg";
import { PhoneCard, PhoneCardProps } from "shared/catalog/PhoneCard";
import { PriceDropBadge } from "ui/PriceDropBadge";
import { usePriceAdvantage } from "utils/usePriceAdvantage";
import { useI18n } from "utils/with-i18n.utils";

import { SubscriptionLabel } from "./SubscriptionLabel";
import NewBadgeSVG from "./new-badge.svg";

type AvailablePhoneCardProps = PhoneCardProps & {
  subscriptionPrice: number;
  originalSubscriptionPrice?: Maybe<number>;
  upfrontPrice: number;
  originalUpfrontPrice: number | null;
  isNew?: boolean;
  isOutOfStock?: boolean;
};

export const AvailablePhoneCard = ({
  isNew,
  isOutOfStock,
  originalUpfrontPrice,
  subscriptionPrice,
  originalSubscriptionPrice,
  upfrontPrice,
  ...props
}: AvailablePhoneCardProps) => {
  const i18n = useI18n();
  const priceAdvantage = usePriceAdvantage({
    originalUpfrontPrice,
    upfrontPrice,
    originalSubscriptionPrice,
    subscriptionPrice,
  });

  const {
    hasSeniorityDiscount,
    smartphoneFamilyHasSubscriptionPriceDrop,
    hasUpfrontPriceDrop,
  } = priceAdvantage;

  const { isBlackFriday } = useSettings();

  return (
    <PhoneCard {...props}>
      {hasSeniorityDiscount && (
        <PriceDropBadge
          className={`px-[12px] py-[1px] bg-gradient-to-r from-neon-blue to-sky-magenta`}
        >
          {i18n.t("catalog.badge.freeUpfront")}
        </PriceDropBadge>
      )}

      {!hasSeniorityDiscount && isBlackFriday && (
        <PriceDropBadge
          className={`px-[12px] py-[1px] bg-little-girl-pink text-black border border-black`}
        >
          <>
            {i18n.t(
              "catalog.badge.priceDrop",
              {},
              {
                html: true,
              },
            )}
          </>
        </PriceDropBadge>
      )}

      {!hasSeniorityDiscount &&
        !isBlackFriday &&
        (smartphoneFamilyHasSubscriptionPriceDrop || hasUpfrontPriceDrop) && (
          <PriceDropBadge className={`px-[12px] py-[1px] bg-pink`}>
            <>
              {i18n.t(
                "catalog.badge.priceDrop",
                {},
                {
                  html: true,
                },
              )}
              <DropArrow />
            </>
          </PriceDropBadge>
        )}

      {isOutOfStock && <OutOfStockOverlay />}
      {isNew && (
        <NewBadgeSVG className="absolute top-24 right-0 lg:top-28 lg:right-6" />
      )}

      <div className="flex flex-col gap-1 items-center w-full">
        <SubscriptionLabel
          subscriptionPrice={subscriptionPrice}
          priceAdvantage={priceAdvantage}
        />
        <p className="px-2 text-sm font-semibold text-white bg-cornflower-blue rounded-full lg:text-base">
          {i18n.t("catalog.badge.insuredBreakageAndTheft")}
        </p>
        <UpfrontLabel
          upfrontPrice={upfrontPrice}
          priceAdvantage={priceAdvantage}
        />
      </div>
    </PhoneCard>
  );
};
