import React from "react";

type DotExtraProps = {
  dotColor: string;
};

type DotProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "children"
> &
  DotExtraProps;

export const Dot = ({ dotColor, ...props }: DotProps) => (
  <div
    className="w-3.5 h-3.5 rounded-full border border-black"
    style={{ backgroundColor: dotColor }}
    {...props}
  />
);
