import cx from "classnames";

import { formatToCurrency } from "utils/price.utils";
import { PriceAdvantage } from "utils/usePriceAdvantage";
import { useI18n } from "utils/with-i18n.utils";

type SubscriptionLabelProps = {
  subscriptionPrice: number;
  priceAdvantage: PriceAdvantage;
};

export const SubscriptionLabel = ({
  subscriptionPrice,
  priceAdvantage: {
    smartphoneFamilyHasSubscriptionPriceDrop,
    formatedOriginalPriceSubscription,
  },
}: SubscriptionLabelProps) => {
  const i18n = useI18n();

  const subscriptionPriceLabelOptions = smartphoneFamilyHasSubscriptionPriceDrop
    ? {
        originalPrice: formatedOriginalPriceSubscription,
        price: formatToCurrency(
          "fr-FR",
          "EUR",
          subscriptionPrice,
          undefined,
          false,
        ).replace(",00", ""),
        originalPriceClassName: "font-semibold text-gray-4 text-sm lg:text-xl",
        priceClassName: "font-bold text-base lg:text-2xl text-pink",
      }
    : {
        price: formatedOriginalPriceSubscription,
        priceClassName: "font-bold text-base lg:text-2xl",
      };

  const subscriptionPriceLabel = i18n.t(
    smartphoneFamilyHasSubscriptionPriceDrop
      ? "catalog.label.subscriptionDiscount"
      : "catalog.label.subscription",
    subscriptionPriceLabelOptions,
    { html: true },
  );

  return (
    <div
      className={cx("flex flex-col items-center text-sm lg:text-lg", {
        "w-full text-center rounded-b-lg":
          smartphoneFamilyHasSubscriptionPriceDrop,
      })}
    >
      <p>{subscriptionPriceLabel}</p>
    </div>
  );
};
