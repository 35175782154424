import cx from "classnames";
import { Maybe } from "graphql";
import { PropsWithChildren } from "react";

import { Card, CardProps } from "ui/Card";
import { Dot } from "ui/Dot";
import { StrapiImage } from "ui/StrapiImage";

export type ImageData = {
  alternativeText: Maybe<string>;
  name?: string;
  medium?: string;
  thumbnail?: string;
};

type PhoneCardExtraProps = {
  modelName: string;
  colorCodes?: string[];
  image: ImageData;
  alternative?: "primary" | "secondary";
  className?: string;
};

export type PhoneCardProps = PhoneCardExtraProps & CardProps;

export const PhoneCard = ({
  children,
  modelName,
  colorCodes,
  image,
  alternative = "primary",
  className,
  ...props
}: PropsWithChildren<PhoneCardProps>) => {
  const borderStyle = alternative === "primary" ? "double" : "double-white";

  const isColorSelectionShown = !!colorCodes;

  return (
    <Card
      border={borderStyle}
      className={cx(
        "flex flex-col gap-4 items-center pt-4 lg:pt-9",
        { "text-white": alternative === "secondary" },
        className,
      )}
      {...props}
    >
      <h3 className="font-bold lg:text-2xl">{modelName}</h3>
      <StrapiImage
        image={{
          alternativeText: image.alternativeText,
          medium: { url: image.medium, name: image.name },
          thumbnail: { url: image.thumbnail, name: image.name },
        }}
        size="medium"
        sizes="40vw"
        className="mx-7 w-28 h-36 lg:mx-14 lg:w-44 lg:h-60"
      />
      {isColorSelectionShown && (
        <div className="flex gap-2.5 justify-center">
          {colorCodes?.map((hexColor, index) => (
            <Dot key={index} dotColor={hexColor} />
          ))}
        </div>
      )}
      {children}
    </Card>
  );
};
