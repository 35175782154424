import * as Types from '../../graphql.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SwapActiveOrderQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SwapActiveOrderQuery = { __typename?: 'query_root', orders: Array<{ __typename?: 'orders', id: number }> };


export const SwapActiveOrderDocument = gql`
    query swapActiveOrder {
  orders(
    order_by: {id: desc}
    limit: 1
    where: {current_state: {name: {_eq: "active"}}}
  ) {
    id
  }
}
    `;
export function useSwapActiveOrderQuery(baseOptions?: Apollo.QueryHookOptions<SwapActiveOrderQuery, SwapActiveOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SwapActiveOrderQuery, SwapActiveOrderQueryVariables>(SwapActiveOrderDocument, options);
      }
export function useSwapActiveOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SwapActiveOrderQuery, SwapActiveOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SwapActiveOrderQuery, SwapActiveOrderQueryVariables>(SwapActiveOrderDocument, options);
        }
export type SwapActiveOrderQueryHookResult = ReturnType<typeof useSwapActiveOrderQuery>;
export type SwapActiveOrderLazyQueryHookResult = ReturnType<typeof useSwapActiveOrderLazyQuery>;
export type SwapActiveOrderQueryResult = Apollo.QueryResult<SwapActiveOrderQuery, SwapActiveOrderQueryVariables>;