import cx from "classnames";
import { DetailedHTMLProps, HTMLAttributes } from "react";
import { match } from "ts-pattern";

type CardExtraProps = {
  border?: "double" | "filled" | "double-white";
  rootClassname?: string;
};

export type CardProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  CardExtraProps;

const getBorderClassname = (border: CardExtraProps["border"]) =>
  match(border)
    .with("double", () => ({
      container: "border-black bg-white has-hover:hover:drop-shadow-black",
      background:
        "absolute top-1 left-1 border rounded-lg border-black w-full h-full",
    }))
    .with("filled", () => ({
      container: "border-black bg-white drop-shadow-black",
      background: "",
    }))
    .with("double-white", () => ({
      container:
        "bg-cornflower-blue border-white has-hover:hover:drop-shadow-white",
      background:
        "absolute top-1 left-1 border rounded-lg border-white w-full h-full",
    }))
    .otherwise(() => ({
      container: "border-black bg-white",
      background: "",
    }));

export const Card = ({
  children,
  rootClassname,
  className,
  border,
  ...props
}: CardProps) => {
  const { container, background } = getBorderClassname(border);

  return (
    <div className={cx("flex relative flex-col-reverse", rootClassname)}>
      <div className={background} />
      {/* Transform GPU because drop-shadow is heavy on browsers making the class bug on Safari without it */}
      <div
        className={cx(
          "relative rounded-lg border transform-gpu",
          container,
          className,
        )}
        {...props}
      >
        {children}
      </div>
    </div>
  );
};
