import { useEffect } from "react";

import { getToken } from "utils/cookies.utils";

import { useSwapActiveOrderQuery } from "./swap-active-order.query.generated";
import { useSwapProductDiscountLazyQuery } from "./swap-product-discount.query.generated";

type useSwapActiveOrderProps = {
  parentOrderId?: number;
};

export const useSwapActiveOrder = ({
  parentOrderId,
}: useSwapActiveOrderProps) => {
  const token = getToken();

  const [getSwapProductDiscount, { data: discount }] =
    useSwapProductDiscountLazyQuery();

  const { data: activeOrders } = useSwapActiveOrderQuery({
    skip: !token,
    onCompleted: ({ orders }) => {
      const orderId = orders[0]?.id;

      if (orderId) {
        getSwapProductDiscount({
          variables: { orderId },
        });
      }
    },
  });

  useEffect(() => {
    if (parentOrderId) {
      getSwapProductDiscount({
        variables: { orderId: parentOrderId },
      });
    }
  }, [getSwapProductDiscount, parentOrderId]);

  return {
    orderId: activeOrders?.orders[0]?.id,
    discount,
  };
};
