import { SwapProductDiscountQuery } from "shared/SwapActiveOrder/swap-product-discount.query.generated";
import { useSwapActiveOrder } from "shared/SwapActiveOrder/useSwapActiveOrder";
import { formatToCurrency } from "utils/price.utils";

export const SENIORITY_THRESHOLD = 20;

type UsePriceManagementProps = {
  originalUpfrontPrice: number | null;
  upfrontPrice: number;
  originalSubscriptionPrice: number | null;
  subscriptionPrice: number;
  parentOrderId?: number;
};

export interface PriceAdvantage {
  discount: SwapProductDiscountQuery | undefined;
  hasUpfrontPriceDrop: boolean | 0 | null | undefined;
  smartphoneFamilyHasSubscriptionPriceDrop: boolean | 0 | null | undefined;
  hasAdvantage: boolean | 0 | null | undefined;
  formatedOriginalPriceSubscription: string;
  formatedOriginalPriceUpfront: string;
  discountPercentage: number | undefined;
  seniority: number;
  currentSeniorityPlan: number | false;
  hasSeniorityDiscount: boolean;
}

export const usePriceAdvantage = ({
  originalUpfrontPrice,
  originalSubscriptionPrice,
  subscriptionPrice,
  upfrontPrice,
  parentOrderId,
}: Partial<UsePriceManagementProps>): PriceAdvantage => {
  const { discount } = useSwapActiveOrder({ parentOrderId });

  const discountPercentage = discount?.discountPercentage?.discount;

  // client has discount for his seniority
  const hasDiscount = !!discountPercentage;

  // prices have gone down (e.g., BlackFriday)
  const hasUpfrontPriceDrop =
    originalUpfrontPrice &&
    upfrontPrice !== originalUpfrontPrice &&
    upfrontPrice !== 0;

  // the smartphone family has a formerSubscriptionPriceInCentsIncludingTax which is less than the new price
  const smartphoneFamilyHasSubscriptionPriceDrop =
    originalSubscriptionPrice &&
    (subscriptionPrice as number) < originalSubscriptionPrice;

  const originalPriceUpfront = originalUpfrontPrice || upfrontPrice;

  const originalPriceSubscription =
    originalSubscriptionPrice || subscriptionPrice;

  const formatedOriginalPriceUpfront = formatToCurrency(
    "fr-FR",
    "EUR",
    originalPriceUpfront ?? 0,
    undefined,
    true,
  );

  const formatedOriginalPriceSubscription = formatToCurrency(
    "fr-FR",
    "EUR",
    originalPriceSubscription ?? 0,
    undefined,
    false,
  );

  const hasAdvantage =
    hasDiscount ||
    hasUpfrontPriceDrop ||
    smartphoneFamilyHasSubscriptionPriceDrop;

  const seniority = discount?.orderSeniority.seniority || 0;
  const hasSeniorityDiscount = seniority >= SENIORITY_THRESHOLD;
  const currentSeniorityPlan = hasSeniorityDiscount && SENIORITY_THRESHOLD;

  return {
    discount,
    hasUpfrontPriceDrop,
    smartphoneFamilyHasSubscriptionPriceDrop,
    hasAdvantage,
    formatedOriginalPriceSubscription,
    formatedOriginalPriceUpfront,
    discountPercentage,
    seniority,
    currentSeniorityPlan,
    hasSeniorityDiscount,
  };
};
