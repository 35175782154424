import * as Types from '../../graphql.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SwapProductDiscountQueryVariables = Types.Exact<{
  orderId: Types.Scalars['Int'];
}>;


export type SwapProductDiscountQuery = { __typename?: 'query_root', orderSeniority: { __typename?: 'OrderSeniority', seniority: number }, discountPercentage: { __typename?: 'OrderSwapGetUpfrontDiscountPercentage', discount: number } };


export const SwapProductDiscountDocument = gql`
    query swapProductDiscount($orderId: Int!) {
  orderSeniority(orderId: $orderId) {
    seniority
  }
  discountPercentage: nest_orderSwap_getUpfrontDiscountPercentage(
    orderId: $orderId
  ) {
    discount
  }
}
    `;
export function useSwapProductDiscountQuery(baseOptions: Apollo.QueryHookOptions<SwapProductDiscountQuery, SwapProductDiscountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SwapProductDiscountQuery, SwapProductDiscountQueryVariables>(SwapProductDiscountDocument, options);
      }
export function useSwapProductDiscountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SwapProductDiscountQuery, SwapProductDiscountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SwapProductDiscountQuery, SwapProductDiscountQueryVariables>(SwapProductDiscountDocument, options);
        }
export type SwapProductDiscountQueryHookResult = ReturnType<typeof useSwapProductDiscountQuery>;
export type SwapProductDiscountLazyQueryHookResult = ReturnType<typeof useSwapProductDiscountLazyQuery>;
export type SwapProductDiscountQueryResult = Apollo.QueryResult<SwapProductDiscountQuery, SwapProductDiscountQueryVariables>;